<template>
  <BaseDialog v-model="show" size-class="!max-w-2xl max-h-[36rem]">
    <template #header>
      <div class="px-4 text-lg font-medium text-gray-100">
        {{ activeWebhook?.id ? `Edit` : 'Add New' }} Delivery Option
      </div>
    </template>
    <AlertsDeliveryCreateChannel :modal="true" />
  </BaseDialog>
</template>

<script setup>
import { computed, onMounted, onBeforeMount } from 'vue';
import useEmitter from '@/composeables/emitter';
import AlertsDeliveryCreateChannel from '@/components/alerts/delivery/AlertsDeliveryCreateChannel.vue';

const emitter = useEmitter();
const emits = defineEmits(['update:model-value', 'update:active-webhook']);
const props = defineProps({
  modelValue: { type: Boolean, default: false },
  activeWebhook: { type: Object, default: null }
});

onMounted(() => {
  emitter.$on('cancel:destination', () => {
    show.value = false;
  });
});
onBeforeMount(() => {
  emitter.$off('cancel:destination');
});

const show = computed({
  get: () => props.modelValue,
  set: val => emits('update:model-value', val)
});
</script>
